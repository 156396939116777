@import '../../styles/customMediaQueries.css';

.viewCounter {
  display: none;
  flex-direction: row;
  color: var(--colorWhite);
  background-color: var(--marketplaceColor);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0 20px;

  & .flames {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & .icon {
      position: relative;
      font-size: 10px;
      & .flamesImage {
        width: 45px;
        height: 45px;
      }
    }
  }

  & .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .desirable {
      font-weight: var(--fontWeightSemiBold);
    }
    & .count {
    }
  }

  &:global(.mobileViewCounter) {
    display: flex;
    margin: 0 24px 24px;
    @media (--viewportLarge) {
      display: none;
    }
  }

  @media (--viewportLarge) {
    display: flex;
  }
}
